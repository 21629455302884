import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MKButton from "components/MKButton";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

const ContactUsButtons = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {isSmallScreen ? (
        // Component for small screens
        <SmallScreenContactUsButtons />
      ) : (
        // Component for large screens
        <LargeScreenContactUsButtons />
      )}
    </div>
  );
};

const SmallScreenContactUsButtons = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      mb={3}
      spacing={4}
    >
      <Grid item xs={12} mb={-3}>
        <DefaultInfoCard
          icon="smartphone"
          title="Phone Number"
          description=" You can schedule with us by calling the number"
          direction="center"
        />
      </Grid>
      <Grid item xs={12}>
        <MKButton color="info">
          <a style={{ color: "white", fontSize: "14px" }} href="tel:+52-951-655-0380">
            +52-951-655-0380
          </a>
        </MKButton>
      </Grid>
      <Grid item xs={12} mb={-3}>
        <DefaultInfoCard
          icon="website"
          title="Whatsapp"
          description="Please click on the WhatsApp Icon in the corner of the page"
          direction="center"
        />
      </Grid>
      <Grid item xs={12}>
        <MKButton color="info">
          <a
            style={{ color: "white", fontSize: "14px" }}
            href="//api.whatsapp.com/send?phone=529516550380&text=I%20want%20to%20learn!"
          >
            Send a message
          </a>
        </MKButton>
      </Grid>
      <Grid item xs={12} mb={-3}>
        <DefaultInfoCard
          icon="email"
          title="E-mail"
          description="Contact us by writting an email"
          direction="center"
        />
      </Grid>
      <Grid item xs={12}>
        <MKButton
          color="info"
          mt={4}
          onClick={() => (window.location = "mailto:cafeconespanolmx@gmail.com")}
        >
          Send an email
        </MKButton>
      </Grid>
    </Grid>
  );
};

const LargeScreenContactUsButtons = () => {
  return (
    <Grid container xs={12} justifyContent="center" alignItems="flex-end" mb={3} spacing={1}>
      <Grid
        container
        item
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        mb={3}
        xs={4}
      >
        <Grid item xs={4} mb={3} align-self="flex-start">
          <DefaultInfoCard
            icon="smartphone"
            title="Phone Number"
            description=" You can schedule with us by calling the number"
            direction="center"
          />
        </Grid>
        <Grid item xs={4} mb={3}>
          <MKButton color="info">
            <a style={{ color: "white", fontSize: "14px" }} href="tel:+52-951-655-0380">
              +52-951-655-0380
            </a>
          </MKButton>
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        mb={3}
        xs={4}
      >
        <Grid item xs={4} mb={3} align-self="flex-start">
          <DefaultInfoCard
            icon="website"
            title="Whatsapp"
            description="Please click on the WhatsApp Icon in the corner of the page"
            direction="center"
          />
        </Grid>
        <Grid item xs={4} mb={3}>
          <MKButton color="info" mt={4}>
            <a
              style={{ color: "white", fontSize: "14px" }}
              href="//api.whatsapp.com/send?phone=529516550380&text=I%20want%20to%20learn!"
            >
              Send a message
            </a>
          </MKButton>
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        mb={3}
        xs={4}
      >
        <Grid item xs={4} mb={3} align-self="flex-start">
          <DefaultInfoCard
            icon="email"
            title="E-mail"
            description="Contact us by writting an email"
            direction="center"
          />
        </Grid>
        <Grid item xs={4} mb={3}>
          <MKButton
            color="info"
            mt={4}
            onClick={() => (window.location = "mailto:cafeconespanolmx@gmail.com")}
          >
            Send an email
          </MKButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactUsButtons;
